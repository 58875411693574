import React from "react";
import { ErrorImage } from "../../Assets/Icons";
import "../FeedBack.scss";

function EmptyScreen() {
  return (
    <div className="feedback-container">
      <ErrorImage style={{ marginTop: 60 }} />
      <span className="title">Oops!</span>
      <span className="description">
        Podemos ter removido esta página. Ou pode ser que tal página nunca tenha
        existido.
      </span>
    </div>
  );
}

export default EmptyScreen;
