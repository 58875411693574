import React, { useState } from "react";
import "./GradientButton.scss";

const GradientButton = ({
  className,
  loading,
  title,
  onPress,
  disabled,
  disabledColors = ["#B3BAC7", "#B3BAC7"],
  colors = ["#003DA5", "#004FD6"],
  pressedColors,
  iconColor,
  noShadow,
  pressedIconColor,
  angle = 90,
  buttonStyle,
  style,
  textStyle,
  icon: renderIcon,
}) => {
  const [isPressed, setIsPressed] = useState(true);
  const getColors = disabled
    ? disabledColors
    : isPressed
    ? pressedColors || [...colors].reverse()
    : colors;
  return (
    <button
      className={className}
      style={{
        ...style,
        ...{
          background: `linear-gradient(90deg, ${getColors[0]}, ${getColors[1]})`,
          border: "none",
          borderRadius: 28,
        },
      }}
      disabled={disabled}
      onPointerEnter={() => {
        if (!loading) setIsPressed(true);
      }}
      onPointerLeave={() => {
        if (!loading) setIsPressed(false);
      }}
      onClick={() => {
        if (!loading) onPress();
      }}
    >
      <div
        style={{
          ...(buttonStyle || {
            borderRadius: 28,
            height: 56,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
          }),
        }}
      >
        {loading ? (
          <div className="loader" />
        ) : (
          <>
            <span
              style={
                textStyle || {
                  fontSize: 16,
                  color: "white",
                  fontWeight: "bold",
                }
              }
            >
              {title}
            </span>
            {renderIcon && (
              <div style={{ marginLeft: 5 }}>
                {renderIcon(isPressed ? pressedIconColor : iconColor)}
              </div>
            )}
          </>
        )}
      </div>
    </button>
  );
};

export default GradientButton;
