export const EyeIcon = ({ color, ...props }) => (
  <svg
    width={18}
    height={13}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M8.997 9.486a2.985 2.985 0 1 1 0-5.972 2.989 2.989 0 0 1 2.993 2.981c0 1.652-1.34 2.991-2.993 2.991ZM9.003 1C4.9 1 2.303 4.052 1.247 5.606c-.323.48-.33 1.311-.012 1.792C2.268 8.96 4.818 12 9.003 12c4.18 0 6.73-3.039 7.762-4.603.32-.48.31-1.311-.012-1.79C15.697 4.051 13.105 1 9.003 1Z"
      stroke={color}
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ClosedEyeIcon = ({ color, ...props }) => (
  <svg
    width={19}
    height={10}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.5 1c1.745 3.667 4.412 5.5 8.003 5.5 3.59 0 6.256-1.833 7.997-5.5m-8 5.5v2m4.293-2.707 1.414 1.414m-11.414 0 1.414-1.414"
      stroke={color}
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const genericImage = require("../Assets/Icons/icon-1.png");
const successImage = require("../Assets/Icons/icon-5.png");
const errorImage = require("../Assets/Icons/icon-10.png");

export const GenericImage = ({ width = 156, height = 156 }) => (
  <img alt="Generic" style={{ width, height }} src={genericImage} />
);
export const ErrorImage = ({ width = 156, height = 156 }) => (
  <img alt="Error" style={{ width, height }} src={errorImage} />
);
export const SuccessImage = ({ width = 156, height = 156 }) => (
  <img alt="Success" style={{ width, height }} src={successImage} />
);
