import axios from 'axios'
import { Buffer } from 'buffer'
import { Config } from '../Actions/Config'

const baseURL = Config.API_URL

const token = Buffer.from(
  `${Config.API_KEY}:${Config.API_PASS}`,
  'utf8',
).toString('base64')

const baseConfig = {
  headers: {
    Authorization: `Basic ${token}`,
  },
  contentTypeFormData: {
    'Content-Type': 'multipart/form-data',
  },
  contentTypeJson: {
    'Content-Type': 'application/json',
  },
}

export const makePostRequest = (url, params = {}) => {
  let finalUrl = baseURL + url
  if (url.startsWith('http')) {
    // hack to make requests to the other URL's
    finalUrl = url
  }

  const promise = new Promise((resolve, reject) => {
    axios
      .post(finalUrl, params, {
        headers: { ...baseConfig.headers, ...baseConfig.contentTypeJson },
      })
      .then(result => {
        resolve(result.data)
      })
      .catch(error => {
        reject(error)
      })
  })
  return promise
}

export const makeGetRequest = (url, params) => {
  let finalUrl = baseURL + url
  if (url.startsWith('http')) {
    // hack to make requests to the other URL's
    finalUrl = url
  }

  const promise = new Promise((resolve, reject) => {
    axios
      .get(finalUrl, {
        params,
        headers: { ...baseConfig.headers, ...baseConfig.contentTypeJson },
      })
      .then(result => {
        resolve(result.data)
      })
      .catch(error => {
        reject(error)
      })
  })
  return promise
}
