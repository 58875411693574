import React from "react";
import "./FormValidation.scss";

const FormValidation = ({ error }) => {
  const isMultiple = !!error.title;
  const renderSingleError = () => (
    <p style={{ ...styles.text, ...styles.textError }}>{error.message}</p>
  );
  const renderMultipleErrors = () => (
    <div>
      <p style={{ ...styles.text, ...styles.title }}>{error.title}</p>
      {error.message.map((message, index) => (
        <p
          key={index + message}
          style={{
            ...styles.text,
            ...(message.isValid ? styles.textSuccess : styles.textInfo),
          }}
        >{`• ${message.message}`}</p>
      ))}
    </div>
  );

  return (
    <div
      style={{
        ...styles.container,
        ...{ bottom: -55 },
        ...(isMultiple ? styles.info : styles.error),
      }}
    >
      <div
        className="arrow"
        style={{
          ...styles.arrow,
          ...(isMultiple ? styles.info : styles.error),
        }}
      />
      {isMultiple ? renderMultipleErrors() : renderSingleError()}
    </div>
  );
};

export default FormValidation;

const styles = {
  container: {
    height: "fit-content",
    display: "flex",
    alignItems: "center",
    paddingLeft: 15,
    paddingRight: 15,
    position: "absolute",
    top: 90,
    borderWidth: 1,
    borderRadius: 8,
    paddingHorizontal: 16,
    paddingVertical: 12,
  },
  error: {
    borderStyle: "solid",
    backgroundColor: "#FEF6F6",
    borderColor: "#DF1414",
  },
  info: {
    borderStyle: "solid",
    backgroundColor: "#fff",
    borderColor: "#000F29",
  },
  arrow: {
    position: "absolute",
    top: -11,
    left: 32,
    width: 20,
    height: 20,
    borderLeftWidth: 1,
    borderTopWidth: 1,
  },
  text: {
    fontSize: 14,
  },
  textError: {
    color: "#DF1414",
  },
  textInfo: {
    color: "#000F29",
  },
  textSuccess: {
    color: "#003DA5",
  },
  title: {
    color: "#000F29",
    fontWeight: "bold",
  },
};
