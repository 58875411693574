import { makePostRequest } from "../Helpers/requests";
import { showError } from "../Helpers/validate";

export const updatePassword = (params, fields, navigate) => {
  const endpoint = "/user/updatePassword";
  const [oldPassRef] = fields;
  return makePostRequest(endpoint, params)
    .then((result) => {
      // trackEvent({
      //   eventName: 'CRM Profile Change Password Complete',
      //   properties: {
      //     event_category: 'Consumer Action',
      //     update_type: params.updateType,
      //   },
      // })

      const { status, message } = result;

      if (status) {
        navigate("/reset-success", { state: { didSuccess: true } });
      } else {
        console.log(message);
      }
    })
    .catch((err) => {
      if (err.response?.data) {
        var { code } = err.response.data;
      }
      switch (code) {
        case 111:
          showError(
            fields,
            "O link para redefinir a palavra-passe está incorreto, por favor tenta novamente."
          );
          break;
        case 10:
          showError(
            [oldPassRef],
            "A palavra-passe parece estar incorreta.\nA tua palvra passe atual consiste em:\n• 8 ou mais carateres\n• contém pelo menos 1 número\n• contém pelo menos 1 caráter especial"
          );
          break;
        case 222:
          try {
            showError(fields, err.response.data.invalid_fields[0]);
          } catch (error) {
            showError(
              fields,
              "Ups! Alguma coisa falhou, tenta novamente mais tarde."
            );
          }
          break;
        default:
          showError(
            fields,
            "Ups! Alguma coisa falhou, tenta novamente mais tarde."
          );
          break;
      }
      // trackEvent({
      //   eventName: 'CRM Profile Change Password Error',
      //   properties: { event_category: 'Consumer Action', ...code ? { error_code: code } : { error_message: err } },
      // })
    });
};
