import React, { Component } from "react";
import { ClosedEyeIcon, EyeIcon } from "../../Assets/Icons";
import FormValidation from "../FormValidation/FormValidation";
import "./CustomTextInput.scss";

class CustomTextInput extends Component {
  constructor(props) {
    super(props);
    const { secureTextEntry } = this.props;
    this.state = {
      secureEntryToggle: secureTextEntry,
      error: null,
      eyePressed: false,
    };

    this.setError = this.setError.bind(this);
  }
  setError(error) {
    this.setState({ error });
  }

  renderEyeIcon = () => {
    const { secureEntryToggle, eyePressed } = this.state;

    return (
      <button
        style={{
          display: "flex",
          padding: 16,
          background: "transparent",
          border: "none",
          justifyContent: "center",
          alignItems: "center",
        }}
        onClick={() =>
          this.setState((state) => ({
            ...state,
            secureEntryToggle: !secureEntryToggle,
          }))
        }
      >
        {secureEntryToggle ? (
          <EyeIcon color={eyePressed ? "#4581E9" : "#000F29"} />
        ) : (
          <ClosedEyeIcon color={eyePressed ? "#4581E9" : "#000F29"} />
        )}
      </button>
    );
  };

  handleOnChangeText = (event) => {
    const value = event.target.value;
    const { error } = this.state;
    const { onChangeText } = this.props;

    onChangeText(value);
    if (error) {
      if (error.fields) {
        error.fields.forEach((ref) => {
          ref.current.setError(null);
        });
      } else {
        this.setState({ error: null });
      }
    }
  };

  render() {
    const {
      style,
      value,
      title,
      placeholder,
      secureTextEntry,
      icon: renderIcon,
      uppercase,
      capitalWords,
    } = this.props;
    const { secureEntryToggle, error } = this.state;
    const showBorders = value;
    const errorStyle = !!error ? { borderColor: "#DF1414" } : {};
    return (
      <div
        className="customTextInputContainer"
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginVertical: 10,
          ...style,
        }}
      >
        {error && error.message && <FormValidation error={error} />}
        <span
          style={{
            color: "#00225C",
            fontSize: 16,
            fontWeight: "bold",
            marginBottom: 5,
          }}
        >
          {title}
        </span>
        <div
          style={{
            borderWidth: 1,
            borderStyle: "solid",
            borderRadius: 8,
            borderColor: "#BEE3FE",
            backgroundColor: "#fff",
            color: "#212529",
            minHeight: 44,
            marginBottom: 40,
            display: "flex",
            flexDirection: "row",
            ...(showBorders && { borderColor: "#4581E9" }),
          }}
        >
          <input
            autoCapitalize={
              uppercase ? "characters" : capitalWords ? "words" : "none"
            }
            style={{
              borderRadius: 8,
              padding: 14,
              color: "#000F29",
              display: "flex",
              flex: 1,
              marginRight: 10,
              ...errorStyle,
              fontSize: 15,
            }}
            onChange={this.handleOnChangeText}
            value={value}
            placeholder={placeholder}
            type={secureEntryToggle ? "password" : "text"}
          />
          {renderIcon && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 16,
              }}
            >
              {renderIcon(showBorders ? "#4581E9" : "#000F29")}
            </div>
          )}
          {secureTextEntry && this.renderEyeIcon()}
        </div>
      </div>
    );
  }
}

export default CustomTextInput;
