import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from './App';
import { ResetPassword, ResetSuccess, EmptyScreen, ReceiptReupload } from './Routes';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="reset-success" element={<ResetSuccess />} />
        <Route path="receipt-reupload" element={<ReceiptReupload />} />
        <Route path="*" element={<EmptyScreen />} />
      </Route>
    </Routes>
  </BrowserRouter>
);