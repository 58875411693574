import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SuccessImage } from "../../Assets/Icons";
import "../FeedBack.scss";

function ResetSuccess() {
  const { state } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    // if (!state?.didSuccess)
    //   navigate('/')
  }, [navigate, state]);

  return (
    <div className="feedback-container">
      <SuccessImage style={{ marginTop: 60 }} />
      <span className="title">Feito!</span>
      <span className="description">
        A tua nova palavra-passe foi guardada com sucesso.
      </span>
    </div>
  );
}

export default ResetSuccess;
