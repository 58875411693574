const validations = {
  required: (value) => !!value,
  password: (value) => [
    value.length >= 8, // 8 or more characters
    /.*[0-9].*/.test(value), // at least 1 number
    /[^a-zA-Z0-9 ]+/.test(value), // at least 1 special character (TODO: Should we count spanish characters as special or should we exclude them?)
    /.*[A-Z].*/.test(value), // at least 1 uppercase
  ],
};
const requiredMessages = {
  required: "Por favor insere o teu {{fieldName}}.",
  requiredRadio: "Seleciona a opção, por favor.",
  requiredPassword: "Escolhe uma palavra-passe segura.",
  requiredPasswordAgain: "Insere a nova palavra-passe.",
};

const validationMessages = {
  required: (fieldName, type) =>
    type
      ? requiredMessages[`required${type}`]
      : `Por favor insere o teu ${fieldName}`,
  password: () => ({
    title: "A tua palavra-passe deve consistir em:",
    messages: [
      "8 ou mais carateres",
      "Conter pelo menos 1 número",
      "Conter pelo menos 1 caráter especial",
      "Conter pelo menos 1 caráter em maiúscula",
    ],
  }),
};

export const validate = (refs, scrollViewRef) => {
  const errors = [];
  refs.forEach((ref) => {
    const field = ref.current;
    const props = field.props;
    field.setError(null); // Clear all the previous validation errors
    const { rules, value, title } = props;
    if (!rules) return;
    const rulesArray = rules.split("|");
    rulesArray.forEach((rule) => {
      if (!rule) return;
      if (!rule.includes(":") && !validations[rule]) return;
      let validationResult;
      if (rule.includes(":")) {
        const ruleName = rule.split(":")[0];
        const ruleVariables = rule.split(":")[1].split(",");
        validationResult = validations[ruleName](value, ...ruleVariables);
      } else {
        validationResult = validations[rule](value);
      }
      if (typeof validationResult === "object") {
        // multiple validations
        const validationMessage = validationMessages[rule]();
        const error = { field, title: validationMessage.title, messages: [] };
        let invalidCount = 0;
        validationResult.forEach((result, index) => {
          error.messages.push({
            message: validationMessage.messages[index],
            isValid: result,
          });
          if (!result) invalidCount++;
        });
        if (invalidCount) errors.push(error);
      } else {
        if (!validationResult) {
          let validationMessage;
          let message;
          if (rule.includes(":")) {
            const ruleName = rule.split(":")[0];
            const ruleVariables = rule.split(":")[1].split(",");
            validationMessage =
              validationMessages[ruleName] || validationMessages["default"];
            message = validationMessage(...ruleVariables);
          } else {
            validationMessage =
              validationMessages[rule] || validationMessages["default"];
            message = validationMessage(title.toLowerCase());
          }
          const error = {
            field,
            message,
          };
          errors.push(error);
        }
      }
    });
  });
  // Show the first error in the array (This can be changed according to how customers wants validations.)
  let firstError = errors[0];
  if (firstError) {
    firstError.field.setError({
      message: firstError.message || firstError.messages,
      title: firstError.title,
    });
  }
  return !errors.length;
};

export const showError = (fields, message) => {
  fields.forEach((ref, index) => {
    const field = ref.current;
    const isLast = index === fields.length - 1;

    if (!isLast) {
      field.setError({ fields });
    } else {
      field.setError({ fields, message });
    }
  });
};
