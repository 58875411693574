import React from 'react';

const ReceiptReupload = () => {
  return (
    <div style={{ paddingTop: '5px' }}>
      <iframe style={{ border: 0, position: 'absolute', overflow: 'hidden' }} width="100%" height="100%" src="http://localhost:8080" title="ReceiptProcessor"></iframe>
    </div>
  );
}

export default ReceiptReupload;