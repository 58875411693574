import './App.scss';
import { Link, Outlet } from 'react-router-dom';
const Logo = require('../src/Assets/logo.png')

function App() {
  return (
    <div className='container'>
      <div className='navbarContainer'>
        <div className='navbar'>
          <Link to={'/'}>
            <img alt='Logo' src={Logo} className='logo' />
          </Link>
        </div>
      </div>
      <Outlet />
    </div>
  );
}

export default App;