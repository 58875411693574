import React, { useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { updatePassword } from "../../Actions/ResetPassAction";
import { GenericImage } from "../../Assets/Icons";
import CustomTextInput from "../../Components/CustomTextInput/CustomTextInput";
import GradientButton from "../../Components/GradientButton/GradientButton";
import { showError, validate } from "../../Helpers/validate";
import "./ResetPassword.scss";

function ResetPassword() {
  // eslint-disable-next-line
  let [searchParams, setSearchParams] = useSearchParams();
  const [requiredFields, setRequiredFields] = useState({
    password: "",
    passwordConfirm: "",
  });
  const [focused, setFocused] = useState(-1);

  const updateRequiredField = (key, value) => {
    setRequiredFields((state) => ({ ...state, [key]: value }));
  };

  const navigate = useNavigate();
  const newPassRef = useRef();
  const newPassAgainRef = useRef();

  const handleSaveNewPassword = () => {
    if (validate([newPassRef, newPassAgainRef])) {
      if (password !== passwordConfirm) {
        showError(
          [newPassRef, newPassAgainRef],
          "As palavras-passe não coincidem."
        );
        return;
      }
      const params = {
        ...requiredFields,
        updateType: "FORGOT",
        token: searchParams.get("code"),
      };
      updatePassword(params, [newPassRef, newPassAgainRef], navigate);
    }
  };

  const { password, passwordConfirm } = requiredFields;
  return (
    <div className="reset-password-container">
      <span className="title">Criar uma nova palavra-passe</span>
      <span className="description">Escolhe uma nova palavra-passe segura</span>
      <GenericImage style={{ marginBottom: 60 }} />
      <CustomTextInput
        ref={newPassRef}
        style={{ zIndex: 2 }}
        title={"Nova palavra-passe"}
        placeholder={"Insere a tua nova palavra-passe"}
        value={password}
        rules={"required:password|password"}
        onChangeText={(password) => updateRequiredField("password", password)}
        secureTextEntry
        focusChange={() => {
          setFocused(1);
          setTimeout(() => {
            setFocused(-1);
          }, 500);
        }}
        focus={focused === 0}
      />
      <CustomTextInput
        ref={newPassAgainRef}
        style={{ zIndex: 1 }}
        title={"Repete a tua palavra-passe"}
        placeholder={"Repete a tua palavra-passe"}
        value={passwordConfirm}
        rules={"required:,PasswordAgain"}
        onChangeText={(passwordConfirm) =>
          updateRequiredField("passwordConfirm", passwordConfirm)
        }
        secureTextEntry
        focus={focused === 1}
        handleButtonPressed={handleSaveNewPassword}
      />
      <GradientButton
        className="gradientButton"
        loading={false}
        style={{
          marginVertical: 16,
        }}
        title={"Guardar nova palavra-passe"}
        onPress={handleSaveNewPassword}
      />
    </div>
  );
}

export default ResetPassword;
